import React, { useState, useEffect, useContext } from 'react'
import MAINLAYOUT from '../components/layouts/mainLayout.jsx'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import SIDE_PANEL from '../components/side_panel/sidePanel.jsx'
import GOOGLEMAP from '../components/googlemap/googlemap.jsx'
import '../assets/css/autosuggest.css'
import LoadingMask from 'react-loadingmask'
import 'react-loadingmask/dist/react-loadingmask.css'
import 'react-modal-overlay/dist/index.css'
import 'react-tabs/style/react-tabs.css'
import Product from '../components/products/products'
import BOTTOM_MENU from '../components/bottom_menu/bottom_menu.jsx'
import SearchBar from '../components/searchBar/searchBar.jsx'
import {
  GlobalStateContext,
  GlobalDispatchContext,
  GlobalCategoriesContext,
} from '../context/GlobalContextProvider'
import GRID_VIEW from '../components/grid_view/grid_view.jsx'
import { isEmpty } from 'lodash'

const Global = require('../context/Global')

const MySwal = withReactContent(Swal)

const searchBar = ({ location }) => {
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)
  const {
    fetchCategories,
    searchStores,
    getAuthenticatedUser,
    reverseGeolocation,
  } = useContext(GlobalCategoriesContext)
  const { pinIcon, lotsPin, somePin } = useStaticQuery(graphql`
    query {
      lotsPin: file(relativePath: { eq: "img/lots_pin.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fixed(width: 35) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      somePin: file(relativePath: { eq: "img/some_pin.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fixed(width: 35) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      pinIcon: file(relativePath: { eq: "img/current_pin.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fixed(width: 25) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const [isSearching, setIsSearching] = useState(false)
  const [openSidePanel, setOpenSidePanel] = useState(false)
  const [userLocation, setUserLocation] = useState(void 0)
  const [storeModal, setStoreModal] = useState(false)
  const [disableBtn, setDisableBtn] = useState(true)
  const [storeAddr, setStoreAddr] = useState('')

  /**
   * This will set the users location
   */
  const getUserLocation = async () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        position => {
          let pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }
          setUserLocation(pos)
        },
        error => {
          console.error('Error Code = ' + error.code + ' - ' + error.message)
        }
      )
    }
  }

  /**
   * This is the new version of componentDidMount,
   * everything inside of useEffect() will be triggered when the component loads
   */
  useEffect(() => {
    getAuthenticatedUser()
      .then(() => {
        let { categories } = state
        if (categories) {
          console.log('categories found')
        } else {
          console.log('no categories - Preloading')
          fetchCategories()
            .then(response => {
              let { data } = response
              dispatch({
                type: 'PRELOAD_CATEGORIES',
                payload: _parseCategories(data),
              })
              console.log('done Preloading')
            })
            .catch(err => {
              console.log(err)
            })
        }
        getUserLocation()
      })
      .catch(ex => {
        console.error('user not found -', ex)
        navigate('/')
      })
  }, [])

  /**
   * This function will save the input string
   * to do a Text search of products
   */
  const _textSearch = inputValue => {
    _setSearchData(inputValue)
    setDisableBtn(false)
  }
  /**
   * This function will trigger the search with searchStores
   * passing the state.searchData array with the item's IDs
   */
  const _searchForItems = () => {
    let { searchData } = state
    let searchParams = {
      lat: userLocation.lat,
      lng: userLocation.lng,
      searchType: typeof searchData === 'string' ? 'byText' : 'byId',
      searchQuery:
        typeof searchData === 'string'
          ? searchData.trim().replace(/^\w/, c => c.toUpperCase())
          : null,
      itemsIds:
        typeof searchData === 'string' ? null : _parseSearchData(searchData),
    }
    dispatch({ type: 'SET_SEARCHRESULTS', payload: void 0 })
    setIsSearching(true)
    searchStores(searchParams)
      .then(response => {
        setIsSearching(false)
        if (isEmpty(response.data)) {
          console.log('---not found')
          _NotFound()
        } else {
          dispatch({ type: 'SET_SEARCHRESULTS', payload: response })
        }
        setIsSearching(false)
      })
      .catch(err => {
        console.log('Error searching ', err)
        setIsSearching(false)
        if (err === 'not authenticated') {
          navigate('/')
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Not Authenticated, Please login again',
          })
        }
      })
  }

  /**
   * This will open/close the side panel
   * @param {*} newState
   */
  const _sidePanelStateChangeHandler = newState => {
    setOpenSidePanel(newState.isOpen)
  }

  /**
   * This function will set the searchData array returned
   * from the SearchBar component
   * @param {array} vals
   */
  const _setSearchData = vals => {
    //vals ? setDisableBtn(false) : setDisableBtn(true)
    if (isEmpty(vals) || vals === '') {
      setDisableBtn(true)
    } else {
      state.searchData = vals
      setDisableBtn(false)
    }
  }

  /**
   * This function will format the data correctly to send over to the API
   * @param {*} vals
   * @returns array
   */
  const _parseSearchData = vals => {
    let parsedData = []
    vals.map(val => {
      parsedData.push(val.value)
    })
    return parsedData
  }
  /**
   * This function will parse the category list from the API
   * and create the needed format for the SelectComponent to work correctly
   * @param {*} rawData
   * @returns
   */
  const _parseCategories = rawData => {
    let products = []
    Object.values(rawData).forEach(value => {
      value.children.map(prod => {
        products.push({ value: prod.id, label: prod.name })
      })
    })
    return products
  }

  /**
   * This will return a Modal when the search returns empty
   */
  const _NotFound = () => {
    MySwal.fire({
      didOpen: () => {
        MySwal.clickConfirm()
      },
    }).then(() => {
      /*let t = []
            state.searchData.map(cat => {
              t.push(cat.label)
            })
              let notFoundCategories = t.join(', ')*/
      return MySwal.fire(
        <div style={{ width: '50vh' }}>
          <div className="pt-3 text-2xl text-center md:text-3xl">
            Item(s) not found
            {/*<div className="text-lg text-center">{notFoundCategories}</div>*/}
          </div>
          <div className="text-base text-center">Try searching again.</div>
        </div>
      )
    })
  }

  const _geoStoreAddr = location => {
    reverseGeolocation(location).then(response => {
      return response.data.results[2].formatted_address
    })
  }

  /**
   * This function will display the Modal with the store's details
   * @param {*} storeDetails
   */
  const _toggletoreModal = storeDetails => {
    setStoreModal(!storeModal)
    setStoreAddr('')
    MySwal.fire({
      didOpen: () => {
        MySwal.clickConfirm()
      },
    }).then(async () => {
      //_geoStoreAddr({ lat: storeDetails.position.lat, lng: storeDetails.position.lng })
      let formattedA = await reverseGeolocation({
        lat: storeDetails.position.lat,
        lng: storeDetails.position.lng,
      })

      let storeData = {
        name: storeDetails.title,
        location: formattedA.results[2].formatted_address,
        retailerDate: storeDetails.retailerDate,
      }
      return MySwal.fire(
        <div style={{ width: '100vh' }}>
          <div
            style={{
              textAlign: 'right',
              fontSize: '20px',
              paddingRight: '15px',
            }}
          >
            <button onClick={() => MySwal.close()} style={{ outline: 'none' }}>
              X
            </button>
          </div>
          <div
            style={{
              height: '1px',
              backgroundColor: '#dbdada',
              width: '100px',
              margin: '10px auto',
            }}
          />
          {storeDetails.items.map((item, i) => {
            return (
              <Product
                key={i}
                itemObj={item}
                storeData={storeData}
                storelatlng={storeDetails.position}
              />
            )
          })}
        </div>
      )
    })
  }

  //Here will validate if the numbers of results is greater than Global.limitResultsOnMap
  //if so, the gridView will be displayed instead of the Mapview.
  if (
    state.searchResult &&
    state.searchResult.data &&
    Object.keys(state.searchResult.data).length > Global.limitResultsOnMap
  ) {
    state.gridView = true
  }

  return (
    <MAINLAYOUT id="app">
      <BOTTOM_MENU />
      <SIDE_PANEL
        pageWrapId={'page-wrap'}
        outerContainerId={'app'}
        width={200}
        isOpen={openSidePanel}
        onStateChange={state => _sidePanelStateChangeHandler(state)}
        customBurgerIcon={false}
      />
      <section className="relative" id="page-wrap">
        <div className="absolute inset-x-0 z-50 h-auto mx-auto rounded max-w-searchBar top-3 shadow-sm">
          <SearchBar
            catchValues={_setSearchData}
            searchData={state.categories}
            searchAction={_searchForItems}
            disableSearchBtn={disableBtn}
            textSearch={_textSearch}
          />
        </div>
        {state.gridView ? (
          <LoadingMask loading={isSearching} loadingText={'Searching...'}>
            <GRID_VIEW searching={isSearching} />
          </LoadingMask>
        ) : (
          <LoadingMask loading={isSearching} loadingText={'Searching...'}>
            <GOOGLEMAP
              pinIcon={pinIcon.childImageSharp.fixed}
              lotsIcon={lotsPin.childImageSharp.fixed}
              someIcon={somePin.childImageSharp.fixed}
              searchResult={state.searchResult}
              zoom={14}
              draggable={true}
              openStoreModal={_toggletoreModal}
              searchData={state.searchData}
            />
          </LoadingMask>
        )}
      </section>
    </MAINLAYOUT>
  )
}

export default searchBar
