import React, { memo } from 'react'
import { Link } from 'gatsby'
import moment from 'moment'

const retailerCard = memo(({ retailer }) => {
  /*   console.log('from retailer card')
  console.log(retailer) */
  //let original = retailer.address.split(' ')
  // {/* <Link to="/mystore" state={{ retailer: retailer.id }}> */}
  return (
    <Link to="/mystore" state={{ retailer: retailer.storeId.S }}>
      <article
        className="bg-white rounded-lg shadow-sm p-4 mb-3
        grid grid-cols-retailer gap-3
        capitalize text-darkGray
        text-sm sm:text-base sm:grid-cols-retailerDesktop
        transition-colors duration-300 hover:bg-green-50
        "
      >
        <div
          className={`${
            'plenty' === 'plenty' ? 'bg-lime' : 'bg-orange'
          } w-5 h-5 rounded-full flex self-center`}
        ></div>
        <div>
          <div className="font-bold">{retailer.name.S}</div>
          <div>
            <div>{retailer.address.S}</div>
            {/* <div>
              5101 <span className="font-bold text-teal">0.7mi</span>
            </div> */}
          </div>
        </div>
        <div>
          <div className="capitalize sm:text-right">last update:</div>
          <div className="font-bold text-teal leading-4 sm:text-right">
            {moment(retailer.dateTime.S).format('MM/DD/YYYY')}
          </div>
        </div>
      </article>
    </Link>
  )
})

export default retailerCard
