import React, { useContext, memo } from 'react'
import { GlobalStateContext } from '../../context/GlobalContextProvider'
import RETAILER_CARD from '../retailer_card/retailer_card.jsx'
import { isEmpty } from 'lodash'
const gridView = memo(({ searching }) => {
  const state = useContext(GlobalStateContext)

  const { searchResult } = state

  var re = []

  if (searchResult && !isEmpty(searchResult.data)) {
    const { data: results } = searchResult
    const entries = Object.entries(results)
    re = entries.map(store => {
      return <RETAILER_CARD key={store[0]} retailer={store[1]} />
    })
    /* Object.keys(results).map((key, index) => {
      results[key].map(item => {
        console.log(item.storeId.S)
      })
    }) */
    /* Object.keys(results).map((key, index) => {
      /* re = results[key].map(item => {
        console.log(item.storeId.S)
        console.log(item.name.S + ' ' + item.quantity.S)
        //return <RETAILER_CARD key={item.storeId.S} retailer={item} />
      }) 
      results[key].map(item => {
        console.log(item.storeId.S)
        console.log(item.name.S)
        console.log(item.products)
        //return <RETAILER_CARD key={item.storeId.S} retailer={item} />
      })
      //console.log(results[key][0].quantity.S)
    })
    console.log(re) */
  }

  return (
    <section className="bg-gray-100 relative overflow-hidden h-screen w-full">
      <div className="container mx-auto p-5 mt-16">
        {searchResult && !isEmpty(searchResult.data) ? (
          re
        ) : (
          <div className="center-text">item not found</div>
        )}
      </div>
    </section>
  )
})

export default gridView
