import React from 'react'
import moment from 'moment'

class Products extends React.Component {
  constructor(props) {
    super(props)
  }
  calculateTime(value) {
    let now = moment()
    let hours = now.diff(value, 'hours')
    let days = now.diff(value, 'days')
    let weeks = now.diff(value, 'weeks')
    if (weeks >= 1) {
      return `${weeks} week(s)`
    } else if (days >= 1) {
      return `${days} day(s)`
    } else {
      return `${hours} hour(s)`
    }
  }

  _reverseGeolocation = currentLocation => {
    console.log('REVERSE PARAMS')
    console.log(currentLocation)
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${currentLocation.lat},${currentLocation.lng}&key=AIzaSyAQhra2X6N6O4LcJpll2Y1qJzFaDuwvMbY`
    )
      .then(result => {
        return result.json()
      })
      .then(data => {
        console.log('REVERSE')
        console.log(data.results[2].formatted_address)
      })
      .catch(err => {
        console.log('ERROR ', err)
      })
  }

  render() {
    let { name: itemName, quantity, dateTime, image } = this.props.itemObj
    let {
      name: storeName,
      location: storeLocation,
      retailerDate,
    } = this.props.storeData
    let { lat, lng } = this.props.storelatlng
    return (
      <>
        <div className="mb-8">
          <div
            style={{ lineHeight: '1.5' }}
            className="px-4 mb-3 text-base font-normal"
          >
            The quantity of <span className="font-bold">{itemName.S}</span> at
            this location was <span className="font-bold">{quantity.S}</span> in
            the last{' '}
            <span className="font-bold">
              {this.calculateTime(retailerDate)}
            </span>
            .
          </div>
          <div
            style={{ lineHeight: '1.5' }}
            className="px-4 mb-3 text-base font-normal"
          >
            <div className="font-bold">{storeName}</div>
            <div className="mb-4">{storeLocation}</div>
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`}
              target="_blank"
              className="p-2 font-bold text-white bg-teal"
            >
              Go There
            </a>
          </div>
          <div>
            <img src={`https://cs-image-upload.s3.amazonaws.com/${image.S}`} />
          </div>
        </div>
        <div
          style={{
            height: '1px',
            backgroundColor: '#dbdada',
            width: '100px',
            margin: '10px auto',
          }}
        />
      </>
    )
  }
}

export default Products
